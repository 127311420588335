.cursor {
  cursor: pointer;
}

.avoidSidebar {
  margin-left: 15.3rem;
  overflow-x: hidden;
}

.fullHeight {
  min-height: 100vh;
}

.max100vh {
  max-height: 100vh;
}

.overflowY {
  overflow-y: scroll;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.overflowX {
  overflow-x: scroll;
}

.scrollCard {
  min-width: 330px;
  /* max-width: 350px; */
}

.input-group.input-group-outline.is-filled .form-label + .form-control,
.input-group.input-group-outline.is-focused .form-label + .form-control {
  border-color: #1a73e8 !important;
  border-top-color: transparent !important;
  box-shadow: inset 1px 0 #1a73e8, inset -1px 0 #1a73e8, inset 0 -1px #1a73e8 !important;
}

.input-group.input-group-outline.is-filled .form-label:after,
.input-group.input-group-outline.is-filled .form-label:before,
.input-group.input-group-outline.is-focused .form-label:after,
.input-group.input-group-outline.is-focused .form-label:before {
  box-shadow: inset 0 1px #1a73e8 !important;
  border-top-color: #1a73e8 !important;
}

.card-image {
  height: 100%;
  object-fit: cover;
  flex-shrink: 0;
}

.card-event img {
  height: 150px;
  object-fit: cover;
}

.image-container {
  height: 150px;
}

@media (max-width: 1199.98px) {
  .sidenav.active {
    transform: translateX(0) !important;
  }
  .avoidSidebar {
    margin-left: 0;
  }
}
